<template>
  <div>
    <router-view/>
  </div>
</template>

<style>
:root{
  --goass-color-bg: #130A06;
  --goass-color-text: #ffffff;
  --goass-color-primary: #FF6C00;
  --goass-color-primary-dark: rgb(204, 87, 3);
  --goass-color-yellow: #F7CA10;

  --goass-size-breakpoint: 550px;
  --goass-size-radius: 9px;
}

::-moz-selection {
  color: var(--goass-color-text);
  background: var(--goass-color-primary);
}

::selection {
  color: var(--goass-color-text);
  background: var(--goass-color-primary);
}

body,
html{
  background: var(--goass-color-bg);
  color: var(--goass-color-text);
  margin: 0;
  padding: 0;
  line-height: 1.1;
}

h1, h2, h3, h4, h5, h6, .title, .relation-one{
  font-family: relation-one, sans-serif;
  font-weight: 700;
  font-style: normal;
}

h2{
  color: var(--goass-color-text);
  font-size: 3.4rem;
  font-weight: initial;
  margin: 0;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 0.2rem;
  line-height: 1;
}

#app {
  text-align: center;
  color: var(--white);
}

#app, input, textarea, button {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

button {
  font-weight: bold;
}

</style>
