export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jukebox"])},
  "form": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Musikwunsch"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikwunsch versendet!"])},
    "input": {
      "placeholder": {
        "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musik"])},
        "artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grüße ..."])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfelder"])}
    }
  },
  "voting": {
    "headline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vote Deinen", _interpolate(_list(0)), "Lieblingshit"])}
  },
  "alert": {
    "server": {
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung fehlgeschlagen!\nBitte versuche es später erneut."])}
    },
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fülle alle (*) Pflichtfelder aus! (Musik, Artist)"])}
  },
  "footer": {
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Produkt von garger.at"])}
  }
}